/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageFunctionalities/pageWhatsappMasivos/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageFunctionalities/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import { TitleUnderlineRightOne, TitleUnderlineRightTwo } from "@components/pageFunctionalities/pageWhatsappMasivos/titleUnderline/titleUnderlineAccordionRight"
import { TitleUnderlineTestimonyOne, TitleUnderlineTestimonyTwo } from "@components/pageFunctionalities/pageWhatsappMasivos/titleUnderline/titleUnderlineTestimony"
import { TitleUnderlineNavbarVerticalOne, TitleUnderlineNavbarVerticalTwo } from "@components/pageFunctionalities/pageWhatsappMasivos/titleUnderline/titleUnderlineNavbarVertical"
import salud from "@components/sharedComponents/testimonies/images/iconos/salud.svg"
import logistica from "@components/sharedComponents/testimonies/images/iconos/logistica.svg"
import concesionarias from "@components/sharedComponents/testimonies/images/iconos/concesionarias.svg"
import educacion from "@components/sharedComponents/testimonies/images/iconos/educacion.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import NavbarVertical from "@components/sharedComponents/navbarVertical/navbarVertical"
import ImageVerticalOne from "@components/pageFunctionalities/pageWhatsappMasivos/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalThree from "@components/pageFunctionalities/pageWhatsappMasivos/images/imagesNavbarVertical/imageThree.webp"
import Industries from "@components/pageFunctionalities/pageWhatsappMasivos/industries/industries"

const TemplatePageWhatsappMasivos = ({ location }) => {

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true


  const titleAccordionRight = <p>
    Con Beex, <TitleUnderlineRightOne underline="añadimos" /> <TitleUnderlineRightTwo underline="funciones únicas" />  para mejorar el compromiso con tus clientes
  </p>
  const titleTestimony = <p>
    Empresas en el mundo han  <TitleUnderlineTestimonyOne underline="automatizado" /> <TitleUnderlineTestimonyTwo underline="sus campañas " /> masivas por WhatsApp
  </p>

  const iconsSilder = i => {
    switch (i) {
      case 0:
        return concesionarias
      case 1:
        return logistica
      case 2:
        return salud
      case 3:
        return educacion
      case 4:
        return ecommerce
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Concesionarias"
      case 1:
        return "Logística"
      case 2:
        return "Salud"
      case 3:
        return "Educación"
      case 4:
        return "Ecommerce"
      default:
        return "Conoce otros casos de éxito"
    }
  }

  const titleNavbarVertical = <p>
    <TitleUnderlineNavbarVerticalOne underline="Mejoramos procesos" /> <TitleUnderlineNavbarVerticalTwo underline="clave" /> de tu empresa para generar experiencias conversacionales únicas
  </p>

  const objOne = {
    tab: "1",
    title: "Notificaciones Personalizadas",
    text: (
      <p>
        Envía mensajes personalizados a gran escala sobre actualizaciones de productos, recordatorios de citas o eventos especiales. La personalización puede basarse en el comportamiento del cliente, preferencias y datos históricos para aumentar la relevancia y el compromiso.
      </p>
    ),
    image: ImageVerticalOne,
  }
  const objTwo = {
    tab: "3",
    title: "Marketing y Promociones",
    text: (
      <p>
        Lanzar campañas de marketing dirigidas, promociones exclusivas, o descuentos a segmentos específicos de clientes. Los mensajes pueden incluir imágenes, videos, y enlaces a landingpage para enriquecer la experiencia del usuario.
      </p>
    ),
    image: ImageVerticalThree,
  }

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <Header
          path="/productos/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/kOvq5IVfmFE?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={true} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Sé parte de las empresas que transformaron la comunicación masiva con todos sus clientes" />
          <AccordionRight data={data.accordionRight} title={titleAccordionRight} description={""} />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <BannerCTA
            title="Crea experiencias conversacionales para mejorar la satisfacción del cliente"
            text="Nuestro equipo comercial está dispuesto para ayudarte a tus necesidades."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <NavbarVertical dataOne={data.navbarVerticalRight} dataTwo={data.navbarVerticalLeft}
            dataSilder={data.navbarVerticalSilder}
            objOne={objOne}
            objTwo={objTwo}
            title={titleNavbarVertical}
            description={''} />
          <BannerCTA
            title="Crea experiencias conversacionales para mejorar la satisfacción del cliente"
            text="Nuestro equipo comercial está dispuesto para ayudarte a tus necesidades"
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Industries data={data.industries} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageWhatsappMasivos
